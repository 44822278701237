<template>
  <div>
    <b-button style="margin-bottom:5px" type="is-info is-light" @click="download(valorMostrar)">
      Download
      </b-button>
    <b-input
      type='textarea'
      maxlength=500
      minlength=0
      v-model='valorMostrar'
      :disabled="true"
      :placeholder='campo.placeholder'
      :icon="campo.icon"
    >
    </b-input>
  </div>
</template>

<script>
export default {
  name: 'WXML',
  props: {
    campo: Object,
    valor: String
  },
  computed: {
    valorMostrar: {
      get: function () {
        return this.formatXml(this.valor)
      }
    }
  },
  methods: {
    formatXml (xml) {
      var formatted = ''
      var indent = ''
      var tab = '\t'
      xml.split(/>\s*</).forEach((node) => {
        if (node.match(/^\/\w/)) indent = indent.substring(tab.length)
        formatted += indent + '<' + node + '>\r\n'
        if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab
      })
      return formatted.substring(1, formatted.length - 3)
    },
    download (xml) {
      let date = (new Date()).toISOString().split('T')[0]

      var filename = 'xml-file_' + date + '.xml'
      var pom = document.createElement('a')
      var bb = new Blob([xml], { type: 'application/xml' })

      pom.setAttribute('href', window.URL.createObjectURL(bb))
      pom.setAttribute('download', filename)

      pom.dataset.downloadurl = ['application/xml', pom.download, pom.href].join(':')
      pom.draggable = true
      pom.classList.add('dragout')

      pom.click()
    }
  }
}
</script>

<style>

</style>
