<template>
  <form @submit.prevent="save" autocomplete="off">
    <!--  fake fields are a workaround for chrome/opera autofill getting the wrong fields -->
    <input id="username" style="display:none" type="text" name="fakeusernameremembered">
    <input id="password" style="display:none" type="password" name="fakepasswordremembered">

    <b-notification v-if="error" type="is-danger" role="alert"> {{error}} </b-notification>
    <b-field v-for="(group,index) in camposVisibles" :key="index" grouped>
      <b-field
        v-for="campo in group"
        :key="campo.id"
        :label="campo.titulo"
        expanded
      >
      <b-field>
        <component
          :ref="campo.nombre"
          :is="getTipo(campo.tipo, campo.editable)"
          :campo="campo"
          :valor="valores[campo.nombre]"
          :configuracion="campo.configuracion"
          :procedimiento="procedimiento"
          :idParent="idParent"
          :identificador="valores['id']"
          @input="preInput(campo.nombre, $event)"
          @replaceAll="replaceAll"
        >
        </component>
         <p class="control" v-if="campo.botonReload">
         <b-button type="is-primary" icon-right="reload" @click="accionBoton(campo)"/>
        </p>
      </b-field>
      </b-field>
    </b-field>
    <b-field grouped position="is-centered" v-if="editable">
      <div class="control">
        <b-button type="is-primary submit" tag="input" native-type="submit" value="Guardar"/>
      </div>
      <div class="control">
        <b-button type="is-warning is-outlined" @click="reset">Cancelar</b-button>
      </div>
    </b-field>
  </form>
</template>

<script>
import WString from '@/components/forms/WString.vue'
import WText from '@/components/forms/WText.vue'
import WXML from '@/components/forms/WXML.vue'
import WDate from '@/components/forms/WDate.vue'
import WDay from '@/components/forms/WDay.vue'
import WSelect from '@/components/forms/WSelect.vue'
import WSelectCompleto from '@/components/forms/WSelectCompleto.vue'
import WInteger from '@/components/forms/WInteger.vue'
import WFloat from '@/components/forms/WFloat.vue'
import WNoEditable from '@/components/forms/WNoEditable.vue'
import WCheck from '@/components/forms/WCheck.vue'
import WTags from '@/components/forms/WTags.vue'
import WPassword from '@/components/forms/WPassword.vue'
import WNumeral from '@/components/forms/WNumeral.vue'
import debounce from 'lodash/debounce'
import WHour from '@/components/forms/WHour.vue'

export default {
  name: 'WForm',
  components: {
    // CardComponent
    WString,
    WText,
    WXML,
    WDate,
    WDay,
    WSelect,
    WSelectCompleto,
    WInteger,
    WFloat,
    WNoEditable,
    WCheck,
    WTags,
    WPassword,
    WNumeral,
    WHour
  },
  props: {
    campos: {
      type: Array,
      default: () => []
    },
    valores: {
      type: Object,
      default: () => {}
    },
    procedimiento: String,
    editable: {
      type: Boolean,
      default: true
    },
    idParent: String,
    tipo: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      error: null,
      valoresOld: {},
      force: false
    }
  },
  beforeMount () {
    // Nos guardamos los valores viejos por si le dan al botón cancelar
    Object.assign(this.valoresOld, this.valores)
  },
  mounted () {
    var camposDesactivar = this.campos.filter(c => c.desactivadoPor)
    camposDesactivar.forEach(c => {
      let check = this.campos.find(check => check.nombre === c.desactivadoPor)
      if (this.valores[check.nombre] === 'SI') {
        if (c.editable === false) {
          c.editable = true
        }
        c.disabled = false
      } else {
        if (c.tipo === 'DATE' || c.tipo === 'DAY') {
          c.editable = false
        }
        c.disabled = true
      }
    })
  },
  computed: {
    camposVisibles () {
      let c = []
      for (var i = 0; i < this.campos.length; i++) {
        var campo = this.campos[i]
        if (campo.mostrar) {
          // Ocultar campo en Insert
          if (!campo.ocultarInsert || (campo.ocultarInsert && this.tipo !== 'INSERT')) {
            // Ocultar campo en Update
            if (!campo.ocultarUpdate || (campo.ocultarUpdate && this.tipo !== 'UPDATE')) {
              if (!c[campo.grupoEdicion]) {
                c[campo.grupoEdicion] = []
              }
              c[campo.grupoEdicion].push(campo)
            }
          }
        }
      }
      return c
    }
  },
  methods: {
    async save () {
      // Buscamos los campos que tenemos que mandar a BBDD
      var parametrosBD = []
      for (let i = 0; i < this.campos.length; i++) {
        if (this.campos[i].actualizarBBDD) {
          parametrosBD.push(this.campos[i].nombre)
        }
      }
      try {
        this.isLoading = true
        const params = {}
        if (this.valores.id !== '' && this.valores.id !== '0' && this.valores.id !== 0 && this.valores.id !== undefined) {
          params.accion = 'UPDATE'
        } else {
          params.accion = 'INSERT'
        }
        if (this.idParent !== undefined && this.idParent !== '') {
          params.idParent = this.idParent
        }
        parametrosBD.forEach(element => {
          params[element] = this.valores[element]
        })
        // Verificar integridad de las claves
        for (let i = 0; i < this.campos.length; i++) {
          if (this.campos[i].tipo === 'PASSWORD') {
            var element = this.campos[i].nombre
            var score = await this.scorePassword(params[element])
            if (params[element] !== '' && score < 60) {
              throw new Error(`<p>Se requiere que la clave tenga 10 carácteres de longitud,</p>
                <p>y tres elementos: una mayúscula, una minúscula, un dígito y un símbolo</p>`)
            }
          }
          if (this.campos[i].tipo === 'NUMERAL') {
            if (params[this.campos[i].nombre]) {
              // Se eliminan los puntos de los miles y se sustituye la coma decimal por un punto
              params[this.campos[i].nombre] = this.replaceAll(params[this.campos[i].nombre], '.', '').replace(',', '.', 'gi')
            }
          }
          if (this.campos[i].tipo === 'FLOAT') {
            if (params[this.campos[i].nombre]) {
              // Se sustituye la coma decimal por punto
              params[this.campos[i].nombre] = params[this.campos[i].nombre].replace(',', '.', 'gi')
            }
          }
        }
        if (this.force) {
          params.accion = params.accion + '_FORCE'
        }
        const { data } = await this.$api.proc.put(this.procedimiento, params)
        if (data.length > 0 && data[0].mensaje === 'OK') {
          this.$buefy.toast.open({
            message: 'Guardado!',
            type: 'is-success'
          })
          delete data.mensaje
          let devolver = { ...this.valores, ...data[0] }
          this.$emit('save', devolver)
          if (params.accion === 'INSERT' || params.accion === 'INSERT_FORCE') {
            this.$emit('insertRow', devolver)
          }
          if (params.accion === 'UPDATE' || params.accion === 'UPDATE_FORCE') {
            this.$emit('updateRow', devolver)
          }
        } else if (data.length > 0 && data[0].accion === 'WARNING') {
          let alerta = {
            mensaje: data[0].mensaje
          }
          this.warningAlert(alerta).then(() => {
            this.force = true
            this.save()
          }).catch(() => {
            // Accion cancelada
          })
        } else {
          this.$buefy.toast.open({
            message: data[0].mensaje,
            type: 'is-danger'
          })
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
        this.force = false
      }
    },
    warningAlert (alerta) {
      return new Promise((resolve, reject) => {
        try {
          this.$buefy.dialog.confirm({
            title: 'INFO',
            message: alerta && alerta.mensaje ? alerta.mensaje : '',
            confirmText: 'OK',
            cancelText: 'Cancel',
            type: 'is-warning',
            onConfirm: () => {
              resolve()
            },
            onCancel: () => {
              reject(new Error('Cancel'))
            }
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    reset () {
      Object.assign(this.valores, this.valoresOld)
      this.$emit('reset')
    },
    input (nombre, event) {
      this.valores[nombre] = event
      let campo = this.campos.find(c => c.nombre === nombre)
      if (campo.recargaOrigen) {
        const params = {}
        params.accion = campo.recargaOrigen
        /*
        let camposRecarga = this.campos.filter(c => c.recargaOrigen === campo.recargaOrigen)
        camposRecarga.forEach(element => {
          let campo = element.nombre
          params[campo] = this.valores[campo]
        })
        */
        var camposBBDD = this.campos.filter(c => c.actualizarBBDD === true)
        camposBBDD.forEach(element => {
          let campo = element.nombre
          params[campo] = this.valores[campo]
        })
        if (this.idParent !== undefined && this.idParent !== '' && this.idParent !== null) {
          params.idParent = this.idParent
        }
        this.recargarDatos(params)
      }
      if (campo.recargarSelects && campo.recargarSelects.length > 0) {
        campo.recargarSelects.forEach(s => {
          var campoSelect = this.campos.find(c => c.nombre === s && (c.tipo === 'SELECT' || c.tipo === 'SELECT_COMPLETO'))
          if (campoSelect) {
            var params = {}
            params.accion = campoSelect.accionValores
            var camposBBDD = this.campos.filter(c => c.actualizarBBDD === true)
            camposBBDD.forEach(element => {
              let campo = element.nombre
              params[campo] = this.valores[campo]
            })
            if (this.idParent !== undefined && this.idParent !== '') {
              params.idParent = this.idParent
            }
            this.recargarDatosSelect(params, campoSelect.nombre)
          }
        })
      }
      if (campo.filtrarTabla) {
        const params = {}
        let camposFiltro = this.campos.filter(c => c.filtrarTabla === campo.filtrarTabla)
        camposFiltro.forEach(element => {
          let campo = element.nombre
          params[campo] = this.valores[campo]
        })
        this.$emit('filtrarTabla', params)
      }
      if (campo.tipo === 'CHECK') {
        this.desactivarCampos(campo)
      }
    },
    async recargarDatos (params) {
      try {
        this.isLoading = true
        const { data } = await this.$api.proc.put(this.procedimiento, params)
        if (data[0]) {
          Object.assign(this.valores, data[0])
          Object.keys(data[0]).forEach(k => {
            this.input(k, data[0][k])
          })
        }
      } catch (error) {
        console.log('a')
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    async recargarDatosSelect (params, campo) {
      try {
        this.isLoading = true
        const { data } = await this.$api.proc.put(this.procedimiento, params)
        this.$refs[campo][0].modificarOpciones(data)
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    getAsyncData: debounce(function (nombre, event) {
      this.input(nombre, event)
    }, 100),
    preInput (nombre, event) {
      let campo = this.campos.find(c => c.nombre === nombre)
      if (campo.tipo === 'SELECT') {
        this.input(nombre, event)
      } else {
        this.getAsyncData(nombre, event)
      }
    },
    getTipo (tipo, editable) {
      switch (tipo) {
        case 'XML':
          return 'WXML'
      }
      if (!this.editable || !editable) {
        return 'WNoEditable'
      } else if (tipo === 'STRING') {
        return 'WString'
      } else if (tipo === 'TEXT') {
        return 'WText'
      } else if (tipo === 'DATE') {
        return 'WDate'
      } else if (tipo === 'DAY') {
        return 'WDay'
      } else if (tipo === 'SELECT') {
        return 'WSelect'
      } else if (tipo === 'SELECT_COMPLETO') {
        return 'WSelectCompleto'
      } else if (tipo === 'INTEGER') {
        return 'WInteger'
      } else if (tipo === 'FLOAT') {
        return 'WFloat'
      } else if (tipo === 'CHECK') {
        return 'WCheck'
      } else if (tipo === 'TAGS') {
        return 'WTags'
      } else if (tipo === 'PASSWORD') {
        return 'WPassword'
      } else if (tipo === 'NUMERAL') {
        return 'WNumeral'
      } else if (tipo === 'HOUR') {
        return 'WHour'
      } else {
        return 'b-input'
      }
    },
    replaceAll (string, search, replace) {
      return string.split(search).join(replace)
    },
    scorePassword (pass) {
      return new Promise((resolve) => {
        var score = 0
        if (!pass) {
          resolve(score)
        }

        if (pass.length >= 10) {
          score += 50
        }

        // Bonus points for mixing it up
        var variations = {
          digits: /\d/.test(pass),
          lower: /[a-z]/.test(pass),
          upper: /[A-Z]/.test(pass),
          nonWords: /\W/.test(pass)
        }

        if (!variations.digits) {
          score -= 12
        } else {
          score += 12
        }

        if (!variations.lower) {
          score -= 12
        } else {
          score += 12
        }

        if (!variations.upper) {
          score -= 12
        } else {
          score += 12
        }

        if (!variations.nonWords) {
          score -= 14
        } else {
          score += 14
        }

        resolve(parseInt(score))
      })
    },
    desactivarCampos (campo) {
      this.isLoading = true
      let nombre = campo.nombre
      let event = this.valores[nombre]
      var camposDesactivar = this.campos.filter(c => c.desactivadoPor === nombre)
      camposDesactivar.forEach(c => {
        c.isLoading = true
        if (event === 'SI') {
          c.editable = true
          c.disabled = false
        } else {
          c.editable = false
          c.disabled = true
        }
        c.isLoading = false
      })
      this.isLoading = false
    },
    async accionBoton (campo) {
      try {
        this.isLoading = true
        const params = {}
        params.accion = campo.botonReload
        var camposBBDD = this.campos.filter(c => c.actualizarBBDD === true)
        camposBBDD.forEach(element => {
          let campo = element.nombre
          params[campo] = this.valores[campo]
        })
        if (this.idParent !== undefined && this.idParent !== '' && this.idParent !== null) {
          params.idParent = this.idParent
        }
        const { data } = await this.$api.proc.put(this.procedimiento, params)
        if (data[0]) {
          Object.assign(this.valores, data[0])
          Object.keys(data[0]).forEach(k => {
            // console.log('recibido de bd: ' + k + ' dato: ' + data[0][k])
            this.input(k, data[0][k])
          })
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>

</style>
