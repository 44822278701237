<template>
  <div>
    <b-modal :active.sync="showModalMessage" scroll="clip">
      <WMessageModal
        :titulo="messageTitle"
        :mensaje="message"
        :accion="messageAction"
        @closeMessage="closeMessage"
      ></WMessageModal>
    </b-modal>
    <b-modal :active.sync="showModal" :full-screen="true" scroll="clip">
      <WPanelForm
        :titulo="titleStack"
        :campos="campos"
        :valores="item"
        :procedimiento="configuracion.procedimiento"
        @closeModal="showModal=false"
        @insertRow="insertRow"
        :idParent="idParent"
      ></WPanelForm>
    </b-modal>
    <b-modal :active.sync="showImportModal" scroll="clip">
      <WImportModal
        :campos="campos"
        :procedimiento="configuracion.procedimiento"
        :select="configuracion.importSelect"
        :nombrePlantilla="configuracion.nombrePlantilla"
        @closeImportModal="showImportModal=false"
      ></WImportModal>
    </b-modal>
    <div class="section level">
    <title-bar class="level-left" :title-stack="titleStack" />
     <WActions class="level-right" :botones="acciones" @accion="accion" />
    </div>
    <section id="table-section" class="section is-main-section full-heigh">
      <card-component class="has-table has-mobile-sort-spaced">
        <WTable
          @checkedRows="checkedRows"
          @save="save"
          @detail="detail"
          :configuracion="configuracion"
          :campos="campos"
          :filtrosCabecera="filtrosCabecera"
          :filasResumen="filasResumen"
          :idParent="idParent"
          ref="wtable"
        />
      </card-component>
      <!-- <pre> Selected: {{ selectedRows }} </pre> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import Notification from '@/components/Notification'
import { mapState } from 'vuex'
import WTable from '@/components/WTable'
import WActions from '@/components/WActions'
import CardComponent from '@/components/template/CardComponent'
import TitleBar from '@/components/template/TitleBar'
import WPanelForm from '@/views/WPanelForm.vue'
import WMessageModal from '@/components/utils/WMessageModal.vue'
import WImportModal from '@/components/utils/WImportModal.vue'

export default {
  name: 'WPanel',
  props: {
    dataprovider: String,
    titulo: String,
    idParent: null
  },
  components: {
    TitleBar,
    CardComponent,
    WTable,
    WActions,
    WPanelForm,
    WMessageModal,
    WImportModal
  },
  data () {
    return {
      selectedRows: [],
      isLoading: false,
      showModal: false,
      showImportModal: false,
      showModalEmergente: false,
      modalSize: 'full', // full -> hace el modal que ocupe toda la pantalla - 'vacio' -> hace el modal centrado y mas pequeño
      configuracion: {},
      filtrosCabecera: [],
      filasResumen: [],
      campos: [],
      acciones: [],
      item: {},
      showModalMessage: false,
      messageTitle: '',
      message: '',
      messageAction: {}
    }
  },
  created () {
  },
  mounted () {
    if (this.dataprovider && this.allowed()) {
      this.getDataProvider()
    }
  },
  methods: {
    async getDataProvider () {
      try {
        this.isLoading = true
        const { data } = await this.$api.dataprovider.get(this.dataprovider)
        if (data.configuracion) {
          this.configuracion = data.configuracion
          this.filtrosCabecera = data.filtros
          this.filasResumen = data.filasResumen
          this.obtenerCampos(data.campos)
          this.obtenerAcciones(data.acciones)
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    checkedRows (rows) {
      this.selectedRows = rows
    },
    obtenerAcciones (acciones) {
      this.acciones = acciones
      // IMPORTAR EXCEL
      if (this.configuracion.allowImport) {
        const importar = {
          accion: 'IMPORT',
          iconoTitulo: 'Importar',
          iconoImagen: 'file-import-outline',
          titulo: 'Importar',
          seleccion: 'NINGUNO',
          tipo: 'DEFAULT',
          color: 'is-dark',
          procedimiento: this.configuracion.procedimiento
        }
        this.acciones.unshift(importar)
      }
      // EXPORTAR EXCEL
      if (this.configuracion.allowExport) {
        const exportar = {
          accion: 'EXPORT',
          iconoTitulo: 'Exportar',
          iconoImagen: 'file-export-outline',
          titulo: 'Export',
          seleccion: 'NINGUNO',
          tipo: 'DEFAULT',
          procedimiento: this.configuracion.procedimiento,
          color: 'is-info'
        }
        this.acciones.unshift(exportar)
      }
      // DELETE
      if (this.configuracion.allowDelete) {
        const borrar = {
          accion: 'DELETE',
          iconoTitulo: 'Borrar',
          iconoImagen: 'delete',
          seleccion: 'MULTIPLES FILAS',
          tipo: 'DEFAULT',
          procedimiento: this.configuracion.procedimiento,
          color: 'is-danger'
        }
        this.acciones.unshift(borrar)
      }
      // COPY
      if (this.configuracion.allowCopy) {
        const copiar = {
          accion: 'COPIAR',
          iconoTitulo: 'Copiar',
          iconoImagen: 'content-copy',
          seleccion: 'UNA FILA',
          tipo: 'DEFAULT',
          color: 'is-warning',
          procedimiento: this.configuracion.procedimiento
        }
        this.acciones.unshift(copiar)
      }
      // INSERT
      if (this.configuracion.allowInsert) {
        const insertar = {
          accion: 'INSERT',
          iconoTitulo: 'Insertar',
          iconoImagen: 'plus',
          seleccion: 'NINGUNO',
          tipo: 'DEFAULT',
          color: 'is-success',
          procedimiento: this.configuracion.procedimiento
        }
        this.acciones.unshift(insertar)
      }
      // REFRESH
      this.acciones.unshift({
        accion: 'REFRESH',
        iconoTitulo: 'Refresh',
        iconoImagen: 'refresh',
        seleccion: 'NINGUNO',
        tipo: 'DEFAULT',
        color: 'is-primary',
        procedimiento: this.configuracion.procedimiento
      })
    },
    detail (row) {
      this.$router.push({ name: 'ventana',
        params: {
          dataprovider: this.configuracion.detail,
          idParent: row.id,
          titulo: row[this.configuracion.detailTitleColumn]
        }
      })
    },
    accion (accion) {
      // let a = this.acciones.find(ac => ac.accion === accion)
      // a -> puede ser undefined cuando la accion es un FORZAR y no está en el array de acciones
      if (accion !== undefined && accion.seleccion !== 'NINGUNO') {
        if (this.selectedRows.length === 0) {
          this.$buefy.toast.open({
            message: 'Selecciona alguna fila',
            type: 'is-danger'
          })
          return
        }
      }
      const rows = this.selectedRows
      switch (accion.tipo) {
        case 'PROCEDIMIENTO':
          if (accion.mensajeWarning && accion.mensajeWarning !== '') {
            this.showMessage('Atención', accion.mensajeWarning, accion)
          } else {
            if (accion.seleccion === 'MULTIPLES FILAS') {
              this.ejecutarProcedimientoMultiple(accion, rows)
            } else {
              this.ejecutarProcedimiento(accion, rows, false)
            }
          }
          break
        case 'ABRIR':
          this.abrirVentana(accion)
          break
        case 'DESCARGAR_DOCUMENTO':
          this.descargarDocumento(accion)
          break
        case 'MODAL':
          this.showModalEmergente = true
          break
        case 'DEFAULT':
          if (accion.accion === 'INSERT') {
            this.item = {}
            this.showModal = true
            return
          }
          if (accion.accion === 'COPIAR') {
            this.item = Object.assign({}, this.selectedRows[0])
            this.item.id = ''
            this.showModal = true
            return
          }
          if (accion.accion === 'REFRESH') {
            this.selectedRows = []
            this.$refs.wtable.loadData()
          }
          if (accion.accion === 'DELETE') {
            this.showMessage('Borrar filas', '¿Estás seguro de borrar la/s fila/s seleccionadas?', accion)
          }
          if (accion.accion === 'DELETE_FORZAR') {
            this.selectedRows.forEach(element => {
              this.forzarDelete(element.id)
            })
          }
          if (accion.accion === 'EXPORT') {
            this.exportarExcel()
          }
          if (accion.accion === 'IMPORT') {
            this.showImportModal = true
          }
          break
        default:
          break
      }
    },
    descargarDocumento (accion) {
      let identificador = this.obtenerIdentificador(accion)
      this.$store.dispatch('descargarDocumento', {
        titulo: accion.iconoTitulo,
        reportName: accion.informe,
        procedure: accion.procedimiento,
        valor1: identificador,
        valor2: accion.valorEstatico
      })
    },
    obtenerIdentificador (accion) {
      switch (accion.seleccion) {
        case 'UNA FILA':
          return this.selectedRows[0][accion.idProcedimiento]
        case 'NINGUNO':
          return this.idParent
        default:
          return null
      }
    },
    exportarExcel () {
      this.$refs.wtable.exportExcel()
    },
    async ejecutarProcedimiento (accion, filasSeleccionadas, forzar) {
      this.isLoading = true
      const params = {}
      // var mensajeInfo = ''
      if (forzar) {
        params.accion = accion.accion + '_FORZAR'
      } else {
        params.accion = accion.accion
      }
      if (this.idParent !== null && this.idParent !== undefined) {
        params.idParent = this.idParent
      }
      if (accion.seleccion === 'UNA FILA') {
        params[accion.idProcedimiento] = filasSeleccionadas[0][accion.idProcedimiento]
      }
      try {
        const { data } = await this.$api.proc.put(this.configuracion.procedimiento, params)
        if (data.length > 0) {
          if (data[0].accion === 'OK' || data[0].mensaje === 'OK') {
            if (data[0].mensaje !== '') {
              this.$buefy.toast.open({
                message: data[0].mensaje,
                type: 'is-success'
              })
            }
            if (accion.seleccion === 'UNA FILA') {
              // Actualizar los datos viejos con los nuevos
              this.$refs.wtable.updateRow({ ...filasSeleccionadas[0], ...data[0] })
            }
            if (data[0].hasOwnProperty('reload') && data[0].reload === '1') {
              this.selectedRows = []
              this.$refs.wtable.loadData()
            }
          }
          if (data[0].accion === 'ERROR') {
            this.$buefy.snackbar.open({
              message: data[0].mensaje,
              type: 'is-danger',
              indefinite: true,
              actionText: 'OK'
            })
          }
          if (data[0].accion === 'WARNING') {
            this.showMessage(accion.titulo, data[0].mensaje, accion)
          }
        } else {
          this.$buefy.snackbar.open({
            message: 'Acción completada',
            type: 'is-success'
          })
        }
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          type: 'is-danger',
          indefinite: true,
          actionText: 'OK'
        })
      }
    },
    async ejecutarProcedimientoMultiple (accion, filasSeleccionadas) {
      this.isLoading = true
      const params = {}
      params.accion = accion.accion
      params.elementos = []
      filasSeleccionadas.forEach(e => params.elementos.push(e[accion.idProcedimiento]))
      try {
        const { data } = await this.$api.array.put(this.configuracion.procedimiento, params)
        if (data.responseProcesar) {
          if (data.responseProcesar.accion === 'OK') {
            let m = data.responseItems.filter(e => e.accion === 'ERROR').map(e => e.mensaje).join('<br/>')
            if (m && m !== '') {
              this.$buefy.toast.open({
                message: m,
                duration: 7000,
                type: 'is-danger'
              })
            } else {
              this.$buefy.toast.open({
                message: data.responseProcesar.mensaje,
                type: 'is-success'
              })
            }
          }
          if (data.responseProcesar.accion === 'ERROR') {
            this.$buefy.snackbar.open({
              message: data.responseProcesar.mensaje,
              type: 'is-danger',
              indefinite: true,
              actionText: 'OK'
            })
          }
          if (data.responseProcesar.hasOwnProperty('reload') &&
            data.responseProcesar.reload === '1') {
            this.selectedRows = []
            this.$refs.wtable.loadData()
          }
        } else {
          this.$buefy.snackbar.open({
            message: 'Acción completada',
            type: 'is-success'
          })
        }
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          type: 'is-danger',
          indefinite: true,
          actionText: 'OK'
        })
      }
    },
    abrirVentana (accion) {
      if (accion.seleccion === 'UNA FILA') {
        if (accion.ventana === 'core.DataPanel') {
          this.$router.push({ name: 'ventana',
            params: {
              dataprovider: accion.accion,
              idParent: this.selectedRows[0].id,
              titulo: this.selectedRows[0][this.configuracion.detailTitleColumn]
            }
          })
        } else {
          let idParent = this.selectedRows[0].id
          this.$router.push({ name: accion.ventana,
            params: { idParent }
          })
        }
      } else {
        if (accion.ventana === 'core.DataPanel') {
          this.$router.push({ name: 'ventana',
            params: {
              dataprovider: accion.accion
            }
          })
        } else {
          this.$router.push({ name: accion.ventana,
            params: {
              dataprovider: accion.accion
            }
          })
        }
      }
    },
    showMessage (titulo, mensaje, accion) {
      this.messageTitle = titulo
      this.message = mensaje
      this.messageAction = accion
      this.showModalMessage = true
    },
    closeMessage (respuesta, accion) {
      this.messageTitle = ''
      this.message = ''
      this.messageAction = {}
      this.showModalMessage = false
      if (respuesta) {
        if (accion.tipo === 'DEFAULT') {
          accion.accion += '_FORZAR'
          this.accion(accion)
        } else if (accion.tipo === 'PROCEDIMIENTO') {
          this.ejecutarProcedimiento(accion, this.selectedRows, respuesta)
        } else if (accion.tipo === 'ABRIR') {

        } else {}
      }
    },
    async forzarDelete (id) {
      try {
        this.isLoading = true
        const params = {}
        params.accion = 'DELETE'
        params.id = id
        const { data } = await this.$api.proc.put(this.configuracion.procedimiento, params)
        if (data.length > 0 && data[0].mensaje === 'OK') {
          this.$refs.wtable.deleteRow(id)
          this.$buefy.toast.open({
            message: 'Borrado!',
            type: 'is-success'
          })
        } else {
          this.$buefy.toast.open({
            message: data[0].mensaje,
            type: 'is-danger'
          })
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    insertRow (row) {
      this.$refs.wtable.insertRow(row)
      if (row.hasOwnProperty('reload') && row.reload === '1') {
        this.selectedRows = []
        this.$refs.wtable.loadData()
      }
    },
    save (row) {
    },
    reload () {
      this.$refs.wtable.loadData()
    },
    async accionBD (procedimiento, params) {
      const { data } = await this.$api.proc.put(procedimiento, params)
      return data
    },
    allowed () {
      if (this.permisos.includes(this.dataprovider)) {
        return true
      } else {
        this.$buefy.toast.open({
          message: 'Not allowed',
          type: 'is-danger'
        })
        return false
      }
    },
    async obtenerCampos (campos) {
      for (let i = 0; campos.length > i; i++) {
        if (campos[i].dinamico) {
          let resultado = await this.$api.proc.put(this.configuracion.procedimiento, {
            accion: campos[i].accion
          })
          if (resultado.data.length > 0) {
            resultado.data.forEach(x => {
              let objeto = Object.assign({}, campos[i])
              objeto.titulo = Object.values(x)[0]
              objeto.nombre = Object.values(x)[0]
              this.campos.push(objeto)
            })
          }
        } else {
          if (campos[i].permiso) {
            if (this.permisos.includes(campos[i].permiso)) {
              this.campos.push(campos[i])
            }
          } else {
            this.campos.push(campos[i])
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'permisos'
    ]),
    calculaTamanoModal () {
      if (this.modalSize === 'full') return true
      return false
    },
    titleStack () {
      if (this.configuracion.webTitleBar) {
        let title = this.configuracion.webTitleBar
        if (this.titulo) {
          title += ' ' + this.titulo
        }
        return title.split('.')
      } else {
        return ['', this.configuracion.titulo]
      }
    }
  },
  watch: {
    $route (to, from) {
      if (this.dataprovider && this.allowed()) {
        // Borramos campos porque si viene de otro dataProvider con los mismo campos se cambia el orden
        this.configuracion = {}
        this.campos = []
        this.filtrosCabecera = []
        this.filasResumen = []
        this.selectedRows = []
        this.acciones = []
        this.item = {}
        this.getDataProvider()
      }
    },
    selectedRows: function () {
      if (this.filasResumen) {
        for (let i = 0; this.filasResumen.length > i; i++) {
          var total = 0
          for (let b = 0; this.selectedRows.length > b; b++) {
            if (this.selectedRows[b][this.filasResumen[i].nombre]) {
              total += parseFloat(this.selectedRows[b][this.filasResumen[i].nombre])
            }
          }
          if (total) {
            this.filasResumen[i].valor = total.toFixed(2)
          } else {
            this.filasResumen[i].valor = 0
          }
        }
      }
    }
  }
}

</script>
<style lang="scss" scope>
  .section {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0 !important;
  }
</style>
