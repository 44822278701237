<template>
  <form>
    <b-timepicker
      v-model='valorMostrar'
      :placeholder="campo.placeholder"
      icon="clock"
      editable
      :enable-seconds="enableSeconds"
      :hour-format="hourFormat"
      :incrementMinutes="configuracion.minutesGranularity"
      :incrementHours="configuracion.hoursGranularity"
    >
    </b-timepicker>
  </form>
</template>

<script>
import moment from 'moment'
export default {
  name: 'WHour',
  data () {
    return {
      enableSeconds: false,
      hourFormat: '24'
    }
  },
  props: {
    campo: Object,
    valor: {
      type: String,
      default: ''
    },
    configuracion: {
      type: Object,
      default: () => {
        return {
          minutesGranularity: null,
          hoursGranularity: null
        }
      }
    }
  },
  computed: {
    valorMostrar: {
      get: function () {
        if (this.valor === '') {
          return null
        } else {
          return moment(this.valor, 'HH:mm').toDate()
        }
      },
      set: function (valorNuevo) {
        this.$emit('input', moment(valorNuevo).format('HH:mm'))
      }
    },
    sampleFormat () {
      const dtf = new Intl.DateTimeFormat(this.locale, {
        hour: 'numeric',
        minute: 'numeric',
        second: this.enableSeconds ? 'numeric' : undefined,
        timezome: 'UTC'
      })
      return dtf.format(new Date(2000, 12, 12, 22, 23, 24))
    }
  }
}
</script>

<style>

</style>
