<template>
  <div>
    <b-input
      type='password'
      minlength=10
      maxlength=50
      v-model='valorMostrar'
      :required='campo.required'
      :placeholder='campo.placeholder'
      :validation-message="error"
      :disabled="campo.disabled"
      :icon="campo.icon"
      autocomplete="new-password"
    >
    </b-input>
    <b-progress style="margin-top: 10px" type="is-info" :value="score"></b-progress>
  </div>
</template>

<script>
export default {
  name: 'WPassword',
  data () {
    return {
      score: 0,
      error: `<p>Se requiere que la clave tenga 10 carácteres de longitud,</p>
        <p>y tres elementos: una mayúscula, una minúscula, un dígito y un símbolo</p>`
    }
  },
  props: {
    campo: Object,
    valor: String
  },
  computed: {
    valorMostrar: {
      get: function () {
        if (this.valor) {
          return this.valor
        } else {
          this.$emit('input', '')
          return ''
        }
      },
      set: async function (valor) {
        this.score = await this.scorePassword(valor)
        this.$emit('input', valor)
      }
    }
  },
  created () {
    this.score = this.scorePassword(this.valorMostrar)
  },
  methods: {
    scorePassword (pass) {
      var score = 0
      if (!pass) {
        return score
      }

      if (pass.length >= 10) {
        score += 50
      }

      // Bonus points for mixing it up
      var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass)
      }

      if (!variations.digits) {
        score -= 12
      } else {
        score += 12
      }

      if (!variations.lower) {
        score -= 12
      } else {
        score += 12
      }

      if (!variations.upper) {
        score -= 12
      } else {
        score += 12
      }

      if (!variations.nonWords) {
        score -= 14
      } else {
        score += 14
      }

      return parseInt(score)
    }
  }
}
</script>
