<template>
  <div>
    <section class="section is-main-section">
       <title-bar :title-stack="titleStack"/>
      <tiles>
        <card-component icon="account-edit" class="tile is-child">
          <WForm
            :campos="campos"
            :valores="item"
            tipo="INSERT"
            :procedimiento="procedimiento"
            @reset="reset"
            @save="save"
            @insertRow="insertRow"
            :idParent="idParent"
          >
          </WForm>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/template/TitleBar'
import Tiles from '@/components/template/Tiles'
import WForm from '@/components/WForm.vue'
import CardComponent from '@/components/template/CardComponent'

export default {
  name: 'WPanelForm',
  components: { Tiles, TitleBar, WForm, CardComponent },
  props: {
    titulo: {
      type: Array,
      default: () => {}
    },
    campos: {
      type: Array,
      default: () => {}
    },
    valores: {
      type: Object,
      default: null
    },
    procedimiento: String,
    idParent: String
  },
  data () {
    return {
      item: {}
    }
  },
  computed: {
    titleStack () {
      let title = this.titulo.slice()
      title.push('Nuevo')
      return title
    }
  },
  created () {
    this.getClearFormObject()
  },
  methods: {
    getClearFormObject () {
      if (this.valores === null) {
        this.campos.map(campo => {
          this.item[campo.nombre] = ''
        })
      } else {
        this.item = this.valores
      }
    },
    save (row) {
      this.$emit('closeModal')
    },
    insertRow (row) {
      this.$emit('insertRow', row)
    },
    reset (index) {
      this.$emit('closeModal')
    }
  }
}
</script>
