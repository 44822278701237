<template>
  <div class="wActions buttons">
    <b-button
      v-for="boton in accionesDefault"
      :key="boton.id"
      @click="$emit('accion', boton)"
      :type="boton.color"
      size="is-small"
      :icon-left="boton.iconoImagen"
    >
    {{ boton.iconoTitulo }}
    </b-button>
    <div v-if="accionesEspeciales.length > 2">
      <b-dropdown hoverable  aria-role="list" position="is-bottom-left">
        <b-button type="is-dark" outlined size="is-small" slot="trigger" icon-left="menu-down">
         Acciones
        </b-button>
        <b-dropdown-item
          aria-role="listitem"
          v-for="boton in accionesEspeciales"
          :key="boton.id"
          @click="$emit('accion', boton)"
          size="is-small">
          <b-icon :icon="boton.iconoImagen" size="is-small"></b-icon>
          <span>{{boton.iconoTitulo}}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
     <b-button
      v-else
      v-for="boton in accionesEspeciales"
      :key="boton.id"
      @click="$emit('accion', boton)"
      :type="boton.color"
      size="is-small"
      :icon-left="boton.iconoImagen"
    >{{ boton.iconoTitulo }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'WActions',
  props: {
    botones: Array
  },
  computed: {
    accionesDefault () {
      return this.botones.filter(b => (b.tipo === 'DEFAULT' || b.posicion === 'DEFAULT'))
    },
    accionesEspeciales () {
      return this.botones.filter(b => (b.tipo !== 'DEFAULT' && b.posicion !== 'DEFAULT'))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /* .button {
    margin: 0.25rem;
  } */
</style>
