<template>
  <form>
    <b-datepicker
      v-model='valorMostrar'
      editable
      :show-week-number='true'
      :placeholder="campo.placeholder"
      icon="calendar-today"
      :first-day-of-week="1"
      :required='campo.required'
      >
        <!--<button class="button is-primary"
          @click="valorMostrar = new Date()"
          >
          <b-icon icon="calendar-today"></b-icon>
          <span>Today</span>
        </button>
        <button class="button is-danger"
          @click="valorMostrar = null"
          >
          <b-icon icon="close"></b-icon>
          <span>Clear</span>
        </button>-->
    </b-datepicker>
  </form>
</template>

<script>
import moment from 'moment'
export default {
  name: 'WDay',
  props: {
    campo: Object,
    valor: {
      type: String,
      default: ''
    }
  },
  computed: {
    valorMostrar: {
      get: function () {
        if (this.valor === '') {
          return null
        } else {
          return moment(this.valor, 'DD/MM/YYYY').toDate()
        }
      },
      set: function (valorNuevo) {
        if (valorNuevo && valorNuevo !== '') {
          this.$emit('input', moment(valorNuevo).format('DD/MM/YYYY'))
        } else {
          this.$emit('input', '')
        }
      }
    }
  }
}
</script>

<style>

</style>
