<template>
  <b-autocomplete
    v-model="valorMostrar"
    :placeholder="campo.placeholder"
    open-on-focus
    :icon="campo.icon"
    :required="campo.required"
    :data="filteredDataArray"
    :loading='isLoading'
    expanded
  >
  </b-autocomplete>
</template>

<script>
export default {
  name: 'WSelectCompleto',
  props: {
    campo: Object,
    valor: {
      type: String,
      default: ''
    },
    procedimiento: String,
    idParent: String,
    identificador: String
  },
  data () {
    return {
      isLoading: false,
      options: [],
      escrito: ''
    }
  },
  beforeMount () {
    this.loadOptions()
  },
  computed: {
    filteredDataArray () {
      return this.options.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.escrito.toLowerCase()) >= 0
      })
    },
    valorMostrar: {
      get: function () {
        console.info(this.valor)
        return this.valor
      },
      set: function (valorNuevo) {
        this.escrito = valorNuevo
        this.$emit('input', valorNuevo)
      }
    }
  },
  methods: {
    async loadOptions () {
      try {
        this.options = []
        if (this.campo.accionValores && this.campo.accionValores.toUpperCase() === 'SI_NO') {
          this.options = ['SI', 'NO']
          return
        }
        this.isLoading = true
        const params = {}
        let proc = 'p_select'
        if (this.procedimiento !== '') {
          params.accion = this.campo.accionValores
          proc = this.procedimiento
          params.idParent = this.idParent
        } else {
          params.tabla = this.campo.accionValores
        }
        if (this.idParent !== '') {
          params.idParent = this.idParent
        }
        params.id = this.identificador
        this.$api.proc.put(proc, params).then((response) => {
          if (response && response.data) {
            const { data } = response
            for (var i = 0; i < data.length; i++) {
              this.options.push(Object.values(data[i])[0])
            }
          }
        })
        /*
        if (this.options.length === 1) {
          this.valorMostrar = this.options[0]
        }
        */
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    modificarOpciones (newOptions) {
      var tmpOptions = []
      if (this.campo.accionValores.toUpperCase() === 'SI_NO') {
        tmpOptions = ['SI', 'NO']
        return
      }
      if (newOptions && newOptions.length > 0) {
        for (var i = 0; i < newOptions.length; i++) {
          tmpOptions[i] = Object.values(newOptions[i])[0]
        }
      }
      this.options = tmpOptions
    }
  }
}
</script>

<style>

</style>
