<template>
  <b-select
    v-model='valorMostrar'
    :placeholder="campo.placeholder"
    :icon="campo.icon"
    :required="campo.required"
    :loading='isLoading'
    expanded
  >
    <option v-for="option in options"
      :value="option"
      :key="option"
      >
      {{ option }}
    </option>
  </b-select>
</template>

<script>
export default {
  name: 'WSelect',
  props: {
    campo: Object,
    valor: String,
    procedimiento: String,
    idParent: String
  },
  data () {
    return {
      isLoading: false,
      options: {}
    }
  },
  beforeMount () {
    this.loadOptions()
  },
  computed: {
    valorMostrar: {
      get: function () {
        return this.valor
      },
      set: function (valorNuevo) {
        this.$emit('input', valorNuevo)
      }
    }
  },
  methods: {
    async loadOptions () {
      try {
        this.options = []
        if (this.campo.accionValores && this.campo.accionValores.toUpperCase() === 'SI_NO') {
          this.options = ['SI', 'NO']
          return
        }
        this.isLoading = true
        const params = {}
        let proc = 'p_select'
        if (this.procedimiento !== '') {
          params.accion = this.campo.accionValores
          proc = this.procedimiento
        } else {
          params.tabla = this.campo.accionValores
        }
        if (this.idParent !== '') {
          params.idParent = this.idParent
        }
        this.$api.proc.put(proc, params).then((response) => {
          if (response && response.data) {
            const { data } = response
            for (var i = 0; i < data.length; i++) {
              this.options.push(Object.values(data[i])[0])
            }
          }
        })
        /*
        if (this.options.length === 1) {
          this.valor = this.options[0]
          this.valorMostrar = this.options[0]
        }
        */
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    seleccionado (option) {
      if (option.value === this.valores[this.campo.nombre].toUpperCase()) { return true }
      return false
    }
  }
}
</script>

<style>

</style>
