<template>
  <form>
    <b-datetimepicker
      v-model='valorMostrar'
      editable
      :show-week-number='true'
      :placeholder="campo.placeholder"
      icon="calendar-today"
      :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }"
      :required='campo.required'
      :datetime-parser="dateParse"
      :disabled="campo.disabled"
      :min-date="minDate"
      :max-date="maxDate"
      :timepicker="configuracion.timepicker"
    >
      <!--<template slot='left'>
        <button class="button is-primary"
          @click.stop.prevent="valorMostrar = new Date()"
          >
          <b-icon icon="clock"></b-icon>
          <span>Now</span>
        </button>
      </template>
      <template slot='right'>
        <button class="button is-danger"
          @click.stop.prevent="$emit('input', '')"
          >
          <b-icon icon="close"></b-icon>
          <span>Clear</span>
        </button>
      </template>-->
    </b-datetimepicker>
  </form>
</template>

<script>
import moment from 'moment'
export default {
  name: 'WDate',
  data () {
    return {
      minDate: null,
      maxDate: null
    }
  },
  props: {
    campo: Object,
    configuracion: {
      type: Object,
      default: () => {
        return {
          timepicker: {}
        }
      }
    },
    valor: {
      type: String,
      default: ''
    }
  },
  beforeMount () {
    if (this.campo.minDate && this.campo.minDate !== '') {
      this.minDate = moment().add(this.campo.minDate, 'day').toDate()
    }
    if (this.campo.maxDate && this.campo.maxDate !== '') {
      this.maxDate = moment().add(this.campo.maxDate, 'day').toDate()
    }
  },
  computed: {
    valorMostrar: {
      get: function () {
        if (this.valor === '') {
          return null
        }
        return moment(this.valor, 'DD/MM/YYYY HH:mm').toDate()
      },
      set: function (valorNuevo) {
        this.$emit('input', moment(valorNuevo).format('DD/MM/YYYY HH:mm'))
      }
    }
  },
  methods: {
    dateParse (strDate) {
      if (strDate === '') {
        return null
      }
      return moment(strDate, 'DD/MM/YYYY HH:mm').toDate()
    }
  }
}
</script>
