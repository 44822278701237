<template>
  <b-numberinput
    v-model='valorMostrar'
    :required='campo.required'
    :placeholder='campo.placeholder'
    :min='campo.min'
    :max='campo.max'
    step='0.0001'
    :controls='false'
  >
  </b-numberinput >
</template>

<script>
export default {
  name: 'WFloat',
  props: {
    campo: Object,
    valor: String
  },
  computed: {
    valorMostrar: {
      get: function () {
        if (this.valor) {
          return parseFloat(this.valor)
        }
        return null
      },
      set: function (valor) {
        this.$emit('input', valor ? valor.toString() : '')
      }
    }
  }
}
</script>

<style>

</style>
