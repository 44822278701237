<template>
  <b-input
    type='text'
    disabled
    v-model='valor'
  >
  </b-input>
</template>

<script>
export default {
  name: 'WNoEditable',
  props: {
    campo: Object,
    valor: String
  }
}
</script>

<style>

</style>
