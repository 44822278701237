<template>
  <div v-if="!configuracion.desactivarSelect">
    <!-- Cabecera de filtros -->
    <div class="filter-card">
      <b-field grouped>
        <b-field v-for="filtro in filtrosCabecera" :label="filtro.titulo" :key="filtro.id">
          <b-input
            v-if="isFilterText(filtro.nombre)"
            v-model="search[filtro.nombre]"
            size="is-small"
            @click.native.stop=""
            @keyup.enter.native="actionFilter(filtro)"
          > </b-input>
          <WSelectFilter
            v-if="isFilterSelect(filtro.nombre)"
            :select="getFilterSelect(filtro.nombre)"
            v-model="search[filtro.nombre]"
            @click.native.stop=""
            @input="actionFilter(filtro)"
          ></WSelectFilter>
          <WDayRangeFilter
            v-if="isFilterRangeDay(filtro.nombre)"
            v-model="search[filtro.nombre]"
            @click.native.stop=""
            @input="actionFilter(filtro)"
          ></WDayRangeFilter>
        </b-field>
      </b-field>
    </div>

    <!-- Tabla de contenido -->
    <div>
      <b-table
        v-if="showTable"
        :data="data"
        :checked-rows.sync="checkedRows"
        :checkable="!configuracion.disableCheck"
        :loading="isLoading"
        :paginated="paginated"
        :per-page="perPage"
        striped
        :narrowed="true"
        :detailed="!configuracion.esconderDesplegable"
        detail-key="id"
        sticky-header
        :height="windowHeight"
        :pagination-simple="true"
        @details-open="startEdit"
        @sort="saveSort"
        :default-sort="sortColumn"
        :default-sort-direction="sortAsc ? 'asc' : 'desc'"
        ref="table"
        class="wtable"
      >
        <template slot-scope="props">
          <b-table-column custom-key="actions">
            <b-button
              v-if="configuracion.allowDetail"
              class="button is-small"
              @click="$emit('detail', props.row)"
              icon-left="card-plus"
            ></b-button>
          </b-table-column>

          <b-table-column
            v-for="column in campos"
            :key="column.idCampo"
            :label="column.titulo"
            :field="column.nombre"
            :centered="false"
            :sortable="true"
            :sticky="column.sticky"
            :custom-sort="customSort"
            :visible="column.mostrar && column.mostrarWeb"
            :style="cellStyle(props, column)"
          >
            <template slot="header" slot-scope="{ column }">
              <b-icon
                v-if="searched.hasOwnProperty(column.field)"
                @click.native.stop="deleteFilter(column.field)"
                icon="filter" size="is-small"
              >
              </b-icon>
                {{ column.label }}
                <br/>

              <b-input
                v-if="isFilterText(column.field)"
                size="is-small"
                v-model="search[column.field]"
                @click.native.stop=""
                @keyup.enter.native="actionFilter(column)"
              ></b-input>

              <WSelectFilter
                v-if="isFilterSelect(column.field)"
                :select="getFilterSelect(column.field)"
                v-model="search[column.field]"
                @click.native.stop=""
                @input="actionFilter(column)"
              ></WSelectFilter>

              <WDayRangeFilter
                v-if="isFilterRangeDay(column.field)"
                v-model="search[column.field]"
                @click.native.stop=""
                @input="actionFilter(column)"
              ></WDayRangeFilter>
            </template>

            <b-tooltip position="is-top" :label="obtenerTooltip(props, column)" multilined>
              <div v-html="props.row[column.nombre]"></div>
            </b-tooltip>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <WForm
            :campos="campos"
            :valores="props.row"
            tipo="UPDATE"
            :procedimiento="configuracion.procedimiento"
            :editable="editable"
            :filtrosCabecera="filtrosCabecera"
            @reset="reset(props.row)"
            @save="save(props.row)"
            @updateRow="updateRow"
            @insertRow="insertRow"
            :idParent="idParent">
          </WForm>
        </template>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="isLoading">
              <p><b-icon icon="dots-horizontal" size="is-large"/></p>
              <p>Fetching data...</p>
            </template>

            <template v-else>
              <p><b-icon icon="emoticon-sad" size="is-large"/></p>
              <p>No hay datos a mostrar</p>
            </template>
          </div>
        </section>

        <template slot="footer" v-if="filasResumen && showTable && data.length > 0">
          <th></th>
          <th></th>
          <th></th>
          <th
            class="is-hidden-mobile"
            v-for="(fila, key, index) in filasResumen"
            :key="index"
            style="z-index:1"
          >
            <div class="th-wrap">{{ fila.titulo }}: {{ fila.valor }}</div>
          </th>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import WForm from '@/components/WForm'
import WSelectFilter from '@/components/forms/WSelectFilter'
import WDayRangeFilter from '@/components/forms/WDayRangeFilter'
import moment from 'moment'

export default {
  name: 'WTable',
  components: { WForm, WSelectFilter, WDayRangeFilter },
  props: {
    configuracion: {
      type: Object,
      default: null
    },
    campos: {
      type: Array,
      default: () => []
    },
    filtrosCabecera: {
      type: Array,
      default: () => []
    },
    filasResumen: {
      type: Array,
      default: () => []
    },
    idParent: null
  },
  data () {
    return {
      isModalActive: false,
      data: [],
      isLoading: false,
      paginated: false,
      perPage: 20,
      checkedRows: [],
      editItem: null,
      search: {},
      searched: {},
      showTable: true,
      windowHeight: window.innerHeight - 180 > 540 ? window.innerHeight - 180 : 540,
      sortColumn: '',
      sortAsc: true,
      sortTipo: 'STRING'
    }
  },
  computed: {
    filterValues () {
      return Object.assign({}, this.$refs.table.filters)
    },
    editable () {
      if (this.configuracion.allowEdit) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    beforeMount () {
      this.loadHeader()
    },
    async loadData () {
      try {
        if (!this.configuracion.desactivarSelect) {
          this.isLoading = true
          this.searched = {}
          this.checkedRows = []
          if (Object.keys(this.search).length > 0) {
            for (var campo in this.search) {
              if (Array.isArray(this.search[campo])) {
                if (this.search[campo].length === 2) {
                  this.searched[campo] = this.search[campo][0]
                  this.searched[campo + '_FIN'] = this.search[campo][1]
                }
              } else {
                this.searched[campo] = this.search[campo]
              }
            }
            /** Modifica las columnas dinamicas en caso de ser necesario */
            let acciones = [ ...new Set(this.campos.filter(x => x.accion).map(x => x.accion)) ]
            for (let i = 0; acciones.length > i; i++) {
              this.searched.accion = acciones[i]
              var result = await this.$api.proc.put(this.configuracion.procedimiento, this.searched)
              this.campos.filter(x => x.accion && x.accion === acciones[i]).forEach(campo => {
                if (result.data.length > 0 && result.data.find(x => Object.values(x)[0] === campo.titulo)) {
                  campo.mostrar = true
                  this.$refs.table.newColumns.filter(x => x.field === campo.titulo)[0].visible = true
                } else {
                  campo.mostrar = false
                  this.$refs.table.newColumns.filter(x => x.field === campo.titulo)[0].visible = false
                }
              })
            }
            this.searched.accion = 'SELECT'
          } else {
            this.searched.accion = 'SELECT_INICIO'
          }
          if (this.idParent !== null) {
            this.searched.idParent = this.idParent
          }
          const { data } = await this.$api.proc.put(this.configuracion.procedimiento, this.searched)
          if (data.length > this.perPage && !this.configuracion.notPaginated) {
            this.paginated = true
          }
          this.data = data
          this.$store.dispatch('setFilter', {
            'dataprovider': this.configuracion.nombre,
            'filter': this.search,
            'sortColumn': this.sortColumn,
            'sortAsc': this.sortAsc
          })
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          this.$forceUpdate()
          this.$router.replace('/login')
        }
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    exportExcel () {
      let excelQuery = {}
      excelQuery.campos = []
      this.campos.forEach(c => {
        if (c.filtro === 'DAY RANGE') {
          excelQuery.campos.push({
            nombre: c.nombre,
            titulo: c.titulo,
            mostrar: c.mostrar,
            tipo: c.tipo,
            columnaColor: c.columnaColor,
            valorFiltro: this.search[c.nombre] === undefined ? '' : this.search[c.nombre][0]
          })
          excelQuery.campos.push({
            nombre: c.nombre + '_FIN',
            titulo: c.titulo,
            mostrar: false,
            tipo: c.tipo,
            valorFiltro: this.search[c.nombre] === undefined ? '' : this.search[c.nombre][1]
          })
        } else {
          excelQuery.campos.push({
            nombre: c.nombre,
            titulo: c.titulo,
            mostrar: c.mostrar,
            tipo: c.tipo,
            columnaColor: c.columnaColor,
            valorFiltro: this.search[c.nombre] === undefined ? '' : this.search[c.nombre]
          })
        }
      })
      if (Object.keys(this.search).length > 0) {
        excelQuery.accion = 'SELECT_INICIO'
      } else {
        excelQuery.accion = 'SELECT'
      }
      if (this.idParent !== null) {
        excelQuery.campos.push({
          nombre: 'idParent',
          titulo: 'idParent',
          mostrar: false,
          tipo: '',
          valorFiltro: this.idParent
        })
      }
      this.$store.dispatch('exportToExcel', {
        procedimiento: this.configuracion.procedimiento,
        titulo: this.configuracion.titulo,
        excelQuery: excelQuery
      })
    },
    closeDetailRow (row) {
      this.$refs.table.closeDetailRow(row)
    },
    async loadDataOne (row) {
      try {
        this.isLoading = true
        let search = {
          accion: 'SELECT_ONE',
          id: row.id
        }
        const { data } = await this.$api.proc.put(this.configuracion.procedimiento, search)
        let index = this.data.findIndex(i => i.id === row.id)
        Object.entries(data[0]).forEach(element => {
          this.data[index][element[0]] = element[1]
        })
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          this.$forceUpdate()
          this.$router.replace('/login')
        }
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    startEdit (row) {
      if (this.configuracion.queryOnDetail) {
        this.loadDataOne(row)
      }
      this.editItem = Object.assign({}, row)
    },
    startInsert () {
    },
    save (row) {
      // this.$emit('save')
      this.$refs.table.closeDetailRow(row)
    },
    reset (row) {
      this.$refs.table.closeDetailRow(row)
    },
    deleteFilter (nombre) {
      delete this.searched[nombre]
      delete this.search[nombre]
      this.loadData()
    },
    deleteRow (idRow) {
      const index = this.data.findIndex((element) => element.id === idRow)
      if (index !== -1) { this.data.splice(index, 1) }
      this.checkedRows = []
    },
    insertRow (row) {
      // Insertamos al final del array la nueva fila
      this.data.push(row)
      if (row.hasOwnProperty('reload') && row.reload === '1') {
        this.loadData()
      }
    },
    updateRow (row) {
      let index = this.data.findIndex(i => i.id === row.id)
      Object.entries(row).forEach(element => {
        this.data[index][element[0]] = element[1]
      })
      if (row.hasOwnProperty('reload') && row.reload === '1') {
        this.loadData()
      }
    },
    isFilterText (field) {
      let a = this.filtrosCabecera.filter(a => a.nombre === field)
      let c = this.campos.filter(c => c.nombre === field)
      if (c.length > 0) {
        return (c[0].filtro === 'STRING')
      } else if (a.length > 0) {
        return (a[0].tipo === 'STRING')
      } else {
        return false
      }
    },
    isFilterSelect (field) {
      let a = this.filtrosCabecera.filter(a => a.nombre === field)
      let c = this.campos.filter(c => c.nombre === field)
      if (c.length > 0) {
        return (c[0].filtro === 'SELECT' || c[0].filtro === 'SELECT_MULTIPLE')
      } else if (a.length > 0) {
        return (a[0].tipo === 'SELECT' || a[0].tipo === 'SELECT_MULTIPLE')
      } else {
        return false
      }
    },
    getFilterSelect (field) {
      let a = this.filtrosCabecera.filter(a => a.nombre === field)
      let c = this.campos.filter(c => c.nombre === field)
      if (c.length > 0) {
        let f = {
          field: field,
          filtro: c[0].filtroValores,
          procedimiento: this.configuracion.procedimiento,
          valorInicial: c[0].valorInicial,
          multiple: c[0].filtro === 'SELECT_MULTIPLE'
        }
        return f
      } else {
        let f = {
          field: field,
          filtro: a[0].filtroValores,
          procedimiento: this.configuracion.procedimiento,
          valorInicial: a[0].valorInicial,
          multiple: a[0].tipo === 'SELECT_MULTIPLE'
        }
        return f
      }
    },
    setFilterSelect (field, value) {
      this.search[field] = value
      this.loadData()
    },
    isFilterRangeDay (field) {
      let a = this.filtrosCabecera.filter(a => a.nombre === field)
      let c = this.campos.filter(c => c.nombre === field)
      if (c.length > 0) {
        return (c[0].filtro === 'DAY RANGE')
      } else if (a.length > 0) {
        return (a[0].tipo === 'DAY RANGE')
      } else {
        return false
      }
    },
    cellStyle (props, columna) {
      try {
        const tableRow = props.row
        const configuracion = this.configuracion
        const colorTitulo = columna.columnaColor
        const colorLetra = columna.letraColor
        const padding = configuracion.paddingTable
        const border = configuracion.borderTable
        return {
          backgroundColor: tableRow[colorTitulo] ? tableRow[colorTitulo] : null,
          color: tableRow[colorLetra] ? tableRow[colorLetra] : null,
          border: border,
          padding: padding
        }
      } catch (e) {
        return ''
      }
    },
    saveSort (column, order) {
      if (!this.configuracion.disabledSort) {
        this.sortColumn = column
        this.sortAsc = order === 'asc'
        this.sortTipo = this.campos.find(c => { return c.nombre === column }).tipo
        this.$store.dispatch('setFilter', {
          'dataprovider': this.configuracion.nombre,
          'filter': this.search,
          'sortColumn': this.sortColumn,
          'sortAsc': this.sortAsc
        })
      }
    },
    getNumericVal (str) {
      if (isNaN(str)) return
      try { return parseFloat(str) } catch (e) { return parseInt(str) }
    },
    customSort (a, b, isAsc) {
      if (this.sortTipo === 'DATE') {
        let adate = moment(a[this.sortColumn], 'DD/MM/YYYY HH:mm')
        let bdate = moment(b[this.sortColumn], 'DD/MM/YYYY HH:mm')
        if (adate.isValid() && bdate.isValid()) {
          return isAsc
            ? adate > bdate ? 1 : -1
            : adate > bdate ? -1 : 1
        } else if (adate.isValid()) {
          return isAsc ? -1 : 1
        } else {
          return isAsc ? 1 : -1
        }
      } else if (this.sortTipo === 'DAY') {
        let adate = moment(a[this.sortColumn], 'DD/MM/YYYY')
        let bdate = moment(b[this.sortColumn], 'DD/MM/YYYY')
        if (adate.isValid() && bdate.isValid()) {
          return isAsc
            ? adate > bdate ? 1 : -1
            : adate > bdate ? -1 : 1
        } else if (adate.isValid()) {
          return isAsc ? -1 : 1
        } else {
          return isAsc ? 1 : -1
        }
      } else if (this.sortTipo === 'FLOAT') {
        let anumber = a[this.sortColumn]
        let bnumber = b[this.sortColumn]
        if (this.getNumericVal(anumber) && this.getNumericVal(anumber)) {
          if (anumber === bnumber) {
            return 0
          } else {
            return isAsc
              ? parseFloat(anumber) > parseFloat(bnumber) ? 1 : -1
              : parseFloat(anumber) > parseFloat(bnumber) ? -1 : 1
          }
        } else if (this.getNumericVal(anumber)) {
          return isAsc ? -1 : 1
        } else {
          return isAsc ? 1 : -1
        }
      } else if (this.sortTipo === 'NUMERAL') {
        let anumber = this.replaceAll(a[this.sortColumn], '.', '').replace(',', '.', 'gi')
        let bnumber = this.replaceAll(b[this.sortColumn], '.', '').replace(',', '.', 'gi')
        if (anumber === bnumber) {
          return 0
        } else {
          return isAsc
            ? parseFloat(anumber) > parseFloat(bnumber) ? 1 : -1
            : parseFloat(anumber) > parseFloat(bnumber) ? -1 : 1
        }
      } else {
        return isAsc
          ? a[this.sortColumn].localeCompare(b[this.sortColumn])
          : b[this.sortColumn].localeCompare(a[this.sortColumn])
      }
    },
    replaceAll (string, search, replace) {
      return string.split(search).join(replace)
    },
    actionFilter (filtro) {
      if (filtro.recargarCabecera) {
        this.loadHeader()
      } else {
        this.loadData()
      }
    },
    /**
     * @author maferrer
     * @date 25/01/2022
     * @return String
     */
    obtenerTooltip (props, column) {
      let tooltip = props.row[column.tooltip]
      if (tooltip) { return tooltip }
      return ''
    },
    async loadHeader () {
      try {
        const configuration = this.configuracion
        if (configuration.headerValues) {
          this.isLoading = true
          this.searched = {}
          if (Object.keys(this.search).length > 0) {
            for (var campo in this.search) {
              if (Array.isArray(this.search[campo])) {
                if (this.search[campo].length === 2) {
                  this.searched[campo] = this.search[campo][0]
                  this.searched[campo + '_FIN'] = this.search[campo][1]
                }
              } else {
                this.searched[campo] = this.search[campo]
              }
            }
          }
          this.searched.accion = this.configuracion.headerValues
          const apiCall = await this.$api.proc.put(this.configuracion.procedimiento, this.searched)
          const result = apiCall.data[0]
          Object.entries(result).forEach(x => {
            let index = this.campos.findIndex(e => e.nombre === x[0])
            if (index > -1) {
              this.campos[index].titulo = x[1]
            }
          })
          this.showTable = false
          this.$nextTick(function () { this.showTable = true })
        }
        this.loadData()
      } catch (e) {
        this.$buefy.toast.open({ message: 'Se ha producido un error', type: 'is-danger' })
      }
    }
  },
  watch: {
    checkedRows: function () {
      /* this.$buefy.snackbar.open({
        message: this.checkedRows,
        queue: false
      }) */
      this.$emit('checkedRows', this.checkedRows)
    },
    configuracion: function () {
      this.search = {}
      this.searched = {}
      this.sortColumn = ''
      this.sortAsc = false
      this.paginated = false
      this.data = []
      if (this.configuracion.procedimiento) {
        this.showTable = false
        this.$nextTick(function () { this.showTable = true })
        if (this.$store.getters.filters[this.configuracion.nombre]) {
          let filterData = this.$store.getters.filters[this.configuracion.nombre]
          this.search = filterData.filter
          this.sortColumn = filterData.sortColumn
          this.sortAsc = filterData.sortAsc
        }
        if (this.configuracion.filasTabla) {
          this.perPage = parseInt(this.configuracion.filasTabla)
        } else {
          this.perPage = 20
        }
        this.loadHeader()
      }
    }
  }
}
</script>

<style scoped>
  /* .wtable {
    height:30rem
  } */
</style>
