<template>
  <section>
    <b-field :addons="false">
      <b-input
        v-model='valorMostrar'
        :required='campo.required'
        :placeholder='campo.placeholder'
        v-cleave="masks"
        @input.native="onInput">
      </b-input>
    </b-field>
  </section>
</template>

<script>
import Cleave from 'cleave.js'

const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind (el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  name: 'WNumeral',
  directives: { cleave },
  props: {
    campo: Object,
    valor: String
  },
  data () {
    return {
      masks: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.'
      }
    }
  },
  created () {
    this.valorMostrar = this.valor
  },
  methods: {
    onInput (event) {
      this.valorMostrar = event.target._vCleave.getFormattedValue()
      this.$emit('input', this.valorMostrar)
    }
  }
}
</script>
