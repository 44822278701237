<template>
  <b-numberinput
    v-model='valorMostrar'
    :required='campo.required'
    :placeholder='campo.placeholder'
    :min='campo.min'
    :max='campo.max'
    step='1'
    :controls='false'
  >
  </b-numberinput >
</template>

<script>
export default {
  name: 'WInteger',
  props: {
    campo: Object,
    valor: String
  },
  computed: {
    valorMostrar: {
      get: function () {
        return parseInt(this.valor)
      },
      set: function (valorNuevo) {
        this.$emit('input', valorNuevo.toString())
      }
    }
  }
}
</script>

<style>

</style>
