<template>
  <b-input
    type='text'
    maxlength=50
    minlength=0
    v-model='valorMostrar'
    :required='campo.required'
    pattern='^[ A-Za-z0-9()+-*\/%_.,À-ºªÿ@\u00f1\u00d1]*$'
    :placeholder='campo.placeholder'
    :disabled="campo.disabled"
    :icon="campo.icon"
    autocomplete="nope"
  >
  </b-input>
</template>

<script>
export default {
  name: 'WString',
  props: {
    campo: Object,
    valor: String
  },
  computed: {
    valorMostrar: {
      get: function () {
        return this.valor
      },
      set: function (valor) {
        this.$emit('input', valor)
      }
    }
  }
}
</script>

<style>

</style>
