<template>
  <b-checkbox
    v-model='valorMostrar'
    :disabled="campo.disabled"
  >
  </b-checkbox>
</template>

<script>
export default {
  name: 'WCheck',
  props: {
    campo: Object,
    valor: {
      type: String,
      default: ''
    }
  },
  computed: {
    valorMostrar: {
      get: function () {
        if (this.valor === '') {
          return false
        }
        return this.valor === 'SI'
      },
      set: function (valor) {
        if (valor) {
          this.$emit('input', 'SI')
        } else {
          this.$emit('input', 'NO')
        }
      }
    }
  }
}
</script>

<style>

</style>
