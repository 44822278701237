<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        Importación Excel
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        <b-steps type="is-info"
          v-model="activeStep"
          :has-navigation="false">
          <b-step-item label="Cargar Datos" icon="file-search-outline" :clickable="false">
            <h1 class="title has-text-centered">Cargar Datos</h1>
            <div class="card">
              <div class="content">
                <section>
                  <b-field v-if="opciones.length>0" horizontal label="Selecciona opción: ">
                    <b-select placeholder="Selecciona opción" required expanded v-model='opcion'>
                      <option
                        v-for='option in opciones' :key='option'
                        :value="option"
                      > {{ option }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field>
                    <b-upload v-model="dropFiles" drag-drop :required="true"
                      @input="checkExcel" name="file">
                      <section class="section">
                        <div class="content has-text-centered">
                          <p> <b-icon icon="upload" size="is-large"></b-icon> </p>
                          <p> Drop your files here or click to upload </p>
                        </div>
                      </section>
                    </b-upload>
                  </b-field>
                  <div class="tags">
                    <span v-for="(file, index) in dropFiles" :key="index" class="tag is-info" >
                      {{file.name}}
                      <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                    </span>
                  </div>
                  <div v-if="nombrePlantilla">
                    <figure class="image is-4by1">
                      <img class="has-ratio" :src="plantillaExcel" :alt="nombrePlantilla">
                    </figure>
                  </div>
                </section>
              </div>
            </div>
          </b-step-item>
          <b-step-item label="Importar" icon="file-check-outline" :clickable="false">
            <h1 class="title has-text-centered">Importar</h1>
            <b-table
              :data="datos"
              :columns="columnas"
              :paginated="true"
              per-page="10"
              striped
              :narrowed="true"
              :hoverable="true"
              :row-class="calculaClase"
            > </b-table>
            <div class="buttons is-centered">
              <b-button class="button"
                type="is-info"
                icon-left="chevron-left"
                @click="backStep">Volver</b-button>
              <b-button class="button"
                type="is-info"
                icon-right="sticker-check-outline"
                @click="importarExcel">Importar</b-button>
            </div>
          </b-step-item>
          <template
            slot="navigation"
            slot-scope="{previous, next}"
            v-if="mostrarNavegacion"
          >
            <b-button
              icon-left="chevron-left"
              :disabled="previous.disabled"
              @click.prevent="previous.action">
            </b-button>
            <b-button
              icon-right="chevron-right"
              :disabled="next.disabled"
              :action="accion"
              @click.prevent="next.action">
            </b-button>
          </template>
        </b-steps>
      </div>
    </div>
    <footer class="card-footer" v-if="showError">
      <p class="title is-6 has-text-danger"> Error: {{ messageError }} </p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'WImportModal',
  data () {
    return {
      dropFiles: [],
      activeStep: 0,
      showError: false,
      messageError: '',
      fichero: {},
      columnas: [],
      datos: [],
      opciones: [],
      opcion: '',
      mostrarNavegacion: false,
      plantillaExcel: ''
    }
  },
  props: {
    campos: {
      type: Array,
      default: () => {}
    },
    procedimiento: String,
    select: String,
    nombrePlantilla: {
      type: String,
      default: () => null
    }
  },
  created () {
    this.getURLPlantilla()
  },
  methods: {
    deleteDropFile (index) {
      this.dropFiles.splice(index, 1)
    },
    getURLPlantilla () {
      this.plantillaExcel = ''
      if (this.nombrePlantilla) {
        axios.get(`plantillas-excel/ ${this.nombrePlantilla}`)
          .then(result => {
            this.plantillaExcel = 'https://bulma.io/images/placeholders/720x240.png'
            if (result.config.url !== '') {
              this.plantillaExcel = result.config.url
            }
          }).catch(error => {
            this.$buefy.toast.open({
              message: `Error: ${error.message}`,
              type: 'is-danger'
            })
          })
      }
    },
    checkExcel (file) {
      file = file[0]
      if (file.type !== 'application/vnd.ms-excel' &&
        file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.type !== 'application/xml' &&
        file.type !== 'text/xml') {
        this.showError = true
        this.messageError = 'El fichero ' + file.name + ' no es un Excel'
        this.deleteDropFile(0)
      } else if (this.opcion === '' && this.opciones.length > 0) {
        this.showError = true
        this.messageError = 'Debes elejir una opción'
      } else {
        this.showError = false
        this.messageError = ''

        this.validarExcel(file)
      }
    },
    async validarExcel (file) {
      // Leemos la excel y recuperamos los campos y los datos para mostrar la tabla
      try {
        this.isLoading = true
        let formData = new FormData()
        formData.append('seleccion', this.opcion)
        formData.append('file', file)
        const { data } = await this.$api.importar.post(this.procedimiento, formData)
        console.log(data)
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
      this.nextStep()
    },
    calculaClase (row) {
      if (row.estado === 'ETI_UPDATE') {
        return 'is-warning'
      }
      if (row.estado === 'ETI_OK') {
        return 'is-success'
      }
      if (row.estado === 'ETI_ERROR') {
        return 'is-danger'
      }
      return ''
    },
    async importarExcel () {
      try {
        this.isLoading = true
        const params = {}
        params.accion = 'IMPORT'
        const { data } = await this.$api.proc.put(this.configuracion.procedimiento, params)
        if (data.length > 0 && data[0].mensaje === 'OK') {
          this.$buefy.toast.open({
            message: 'Importada!',
            type: 'is-success'
          })
          this.$emit('closeImportModal')
        } else {
          this.$buefy.toast.open({
            message: data[0].mensaje,
            type: 'is-danger'
          })
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    nextStep () {
      this.activeStep += 1
    },
    backStep () {
      this.activeStep -= 1
      if (this.activeStep === 0) {
        this.deleteDropFile(0)
      }
    }
  }
}
</script>

<style>
.content ul {
  list-style: none;
}
tr.is-info {
  background: #167df0;
  color: #fff;
}
.tags:not(:last-child) {
    margin-bottom: 0rem;
}
.card:not(:last-child) {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
}
.card-content {
  padding: 0rem;
}
</style>
